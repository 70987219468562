import { useNavigate } from '@hooks/useNavigate';
import { Loader } from '@shared/ui/loader/Loader';
import React from 'react';

const getPatch = (params: string) => {
  switch (params) {
    case 'vc':
      return '/cabinet/volunteer';
    default:
      return '404';
  }
};

const getTypeFromParam = (typeParam: string) => {
  switch (typeParam) {
    case 'ab':
      return 'atb';
    case 'fg':
      return 'fozzy_group';
    case 'ea':
      return 'eaptechka';
    case 'wg':
      return 'wog';
    case 'es':
      return 'ekta-socar';
    case 'et':
      return 'easter-atb';
    case 'ef':
      return 'easter-fozzy-group';
    case 'oc':
      return 'one-card';
    case 'ff':
      return 'fishka-fuel';
    default:
      return undefined;
  }
};

const getCategoryFromParam = (param: string) => {
  switch (param) {
    case 'ea':
      return 'easter';
    case 'fu':
      return 'online-fuel';
    case 'om':
      return 'online-medicaments';
    case 'of':
      return 'online-food';
    default:
      return undefined;
  }
};

const useRedirect = (id: string) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    const [patchParam, categoryParam, typeParam] = id.split('-');

    const patch = getPatch(patchParam);
    const type = getTypeFromParam(typeParam);
    const category = getCategoryFromParam(categoryParam);

    if (patch === '404') {
      navigate(patch);
    }
    navigate(`${patch}?${category ? `category=${category}&shop=${type}` : ''}`);
  }, [id]);
};

const Redirect: React.FC<{ id: string }> = ({ id }) => {
  useRedirect(id);

  return (
    <div className="flex justify-center items-center" style={{ minHeight: 500 }}>
      <Loader />
    </div>
  );
};

export default Redirect;
